import React from "react"
import Layout from "../components/layout"
import "../styles/mains.scss"

const NotFoundPage = () => (
  <Layout>
    <div className="section">
      <div className="container">
        <h1>404: Not Found</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
